import { withTheme } from '@emotion/react'
import React from 'react'
import PriceStyles from './Price.styles'

const GlobalStyles = withTheme(() => (
  <>
    <PriceStyles />
  </>
))

export default GlobalStyles
