module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://latino.usdish.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-KDC5QS","brandTokens":["TND"],"mapiBrandToken":"DN","siteName":"latino.usdish","alternateName":"latino.usdish.com","siteURL":"https://latino.usdish.com","defaultTitleTemplate":"","defaultPhone":"8556040142","phoneSymbol":"-","defaultPromoCode":"70829","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046134","vwoProjectID":"894940","cobra":{"sitePath":"latino.usdish.com","buttonLink":"https://latino.dish.com/partner/clearlink/?tfn={ROTATING_NUMBER}&alt_rep_id=19481","buttonText":"Ordena en línea","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"latino.usdish","short_name":"latino.usdish","start_url":"/","background_color":"#E10B3B","theme_color":"#E10B3B","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6d4c087210f4f3bd358fc5853b09ba60"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
